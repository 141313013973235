var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"tabs__content"},[_c('document-table',{attrs:{"documents":_vm.documentList,"docsProps":_vm.docsProps},on:{"update:docsProps":function($event){_vm.docsProps=$event},"update:docs-props":function($event){_vm.docsProps=$event},"send":(docNumber) => {
        _vm.currentDocNumber = docNumber;
        _vm.showDocSendingDialog = true;
      },"download":(docNumber) => {
        _vm.currentDocNumber = docNumber;
        _vm.showDocDownloadingDialog = true;
      }}}),_c('doc-getter-dialog',{attrs:{"show-dialog":_vm.showDocSendingDialog,"doc-number":_vm.currentDocNumber,"is-send-mode":true},on:{"agree":({ payload: email }) => {
        _vm.closeAllDialogs();
        _vm.targetEmail = email;
        _vm.useDocSendingDialogNotice = !_vm.useDocSendingDialogNotice;
      },"disagree":_vm.closeAllDialogs}}),_c('doc-getter-dialog',{attrs:{"show-dialog":_vm.showDocDownloadingDialog,"doc-number":_vm.currentDocNumber},on:{"agree":_vm.closeAllDialogs,"disagree":_vm.closeAllDialogs}}),_c('x-notice',{attrs:{"show":_vm.useDocSendingDialogNotice}},[_vm._v(" Документы успешно отправлены на email: "+_vm._s(_vm.targetEmail)+" ")])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }