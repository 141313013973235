
import { Component } from "vue-property-decorator";
import XDropdown from "./SimpleDropdown.vue";

@Component({
  components: { XDropdown },
})
class ActionDropdown extends XDropdown {
  itemProps = [
    {
      icon: "mdi-send",
      title: "Отправить email",
      evtName: "send",
    },
    {
      icon: "mdi-download",
      title: "Скачать",
      evtName: "download",
    },
  ];
}

export default ActionDropdown;
