
import { Component } from "vue-property-decorator";
import XDropdown from "./SimpleDropdown.vue";

@Component({
  components: { XDropdown },
})
class ActionDropdownEDO extends XDropdown {
  itemProps = [
    {
      icon: "mdi-send",
      title: "Отправить email",
      evtName: "send",
    },
    {
      icon: "mdi-download",
      title: "Скачать",
      evtName: "download",
    },
    {
      icon: "mdi-file-document-check-outline",
      title: "Принять",
      evtName: "accept",
    },
    {
      icon: "mdi-file-document-minus-outline",
      title: "Отклонить",
      evtName: "cancel",
    },
  ];
}

export default ActionDropdownEDO;
