
import { Component, Mixins } from "vue-property-decorator";
import { ContractDocumentsByContract } from "@/models/contract";
import Page from "@/components/for-page-grid/Page.vue";
import ContractDocumentTable from "@/components/for-documents-view/ContractDocumentTable.vue";
import ContractDocGetterDialog from "@/components/for-documents-view/ContractDocGetterDialog.vue";
import XNotice from "@/components/hoc/SimpleNotice.vue";
import AppApiMixin from "@/components/mixins/AppApi.vue";
import { mapGetters } from "vuex";
import DocGetterMixin from "@/components/mixins/DocGetter.vue";

@Component({
  components: {
    Page,
    ContractDocumentTable,
    ContractDocGetterDialog,
    XNotice,
  },
  computed: { ...mapGetters("contract", { contractId: "id" }) },
})
class ContractDocuments extends Mixins(AppApiMixin, DocGetterMixin) {
  documentList: ContractDocumentsByContract[] = [];
  currentDocId = -1;
  currentDocName = '';

  // Состояния всплывающих элементов страницы.
  showDocSendingDialog = false;
  useDocSendingDialogNotice = false;
  targetEmail = "";

  showDocDownloadingDialog = false;

  public mounted() {
    const { contractId } = this;

    this.getContractDocumentsByContract(
        {contractId: contractId},
        (documentList: ContractDocumentsByContract[]) => {
          this.documentList = documentList;
        }
    );
  }

  public closeAllDialogs() {
    this.showDocSendingDialog = false;
    this.showDocDownloadingDialog = false;

    this.targetEmail = "";
    this.currentDocId = -1;
    this.currentDocName = "";
  }

  public getDocName() {
    return this.documentList.find((doc) => {
      return doc.$номерЗаписи === this.currentDocId;
    })?.["тип документа"] || '';
  }

  // public sendEmailContactDocument(documentId) {
  //   const { contractId } = this;
  //
  //   const reqBody: ContractDocumentsAsReqBody = {
  //     contractId,
  //     documentId,
  //   };
  //
  //   this.getContractDocumentByContract(reqBody)
  //     .then((response) => {
  //       // if (!isSendMode) {
  //       this.downloadDocument(response);
  //       // return propagateCustomEvt(evt.name);
  //       // }
  //       // propagateCustomEvt(evt.name, values.email);
  //     })
  //     .catch((_) => (this.setErrorMessage('Ошибка получения документа')));
  // }
  //
  // // @Emit("click")
  // public downloadContactDocument(documentId): void {
  //   const { contractId } = this;
  //
  //   const reqBody: ContractDocumentsAsReqBody = {
  //     contractId,
  //     documentId,
  //   };
  //
  //   this.getContractDocumentByContract(reqBody)
  //     .then((response: AxiosResponse) => {
  //       console.log('getContractDocumentByContract')
  //       this.downloadDocument(response);
  //     })
  //     .catch((_) => (this.setErrorMessage('Ошибка получения документа')));
  // }
}

export default ContractDocuments;
